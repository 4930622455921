<nav class="navbar navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/admin">
      <img
        class="logoMavo"
        src="../../assets/mavomarcaaguanegro.png"
        alt="mr mavo store"
        width="150px"
      />
    </a>
    <div class="d-flex">
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        (click)="logout()"
        *ngIf="_authService.auth.user | async as user; else showLoginOptions"
      >
        <i class="fa-solid fa-right-from-bracket"></i>
      </button>
      <ng-template #showLoginOptions>
        <button type="button" class="btn btn-danger btn-sm" (click)="login()">
          <i class="fa-brands fa-google"></i>
        </button>
      </ng-template>
    </div>
  </div>
</nav>
<div class="container pt-3">
  <ng-container *ngIf="!loading">
    <div *ngIf="_authService.auth.user | async as user; else showLogin">
      <ng-container *ngIf="hasAccess === true && loading !== true">
        <router-outlet></router-outlet>
      </ng-container>
      <ng-container *ngIf="hasAccess !== true && loading !== true">
        <div class="alert alert-warning" role="alert">
          Solo puede ingresar un administrador...
        </div>
      </ng-container>
    </div>
    <ng-template #showLogin>
      <div class="alert alert-secondary" role="alert" *ngIf="loading !== true">
        Inicie sesión...
      </div>
    </ng-template>
  </ng-container>
  <div class="loading" *ngIf="loading">Loading&#8230;</div>
</div>
