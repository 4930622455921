import { Injectable } from '@angular/core';
import { Ticket } from '../interfaces/ticket.interface';
declare const ConectorEscposAndroid: any;
import * as moment from 'moment';
import { MovementsService } from './movements.service';
import { ProductsService } from './products.service';
import { BrandsService } from './brands.service';
import { CategoriesService } from './categories.service';
import { Product } from '../interfaces/product.interface';
import { Movement } from '../interfaces/movement';
import { Brand } from '../interfaces/brand.interface';
import { Categorie } from '../interfaces/categorie.interface';
import { MainCategory } from '../interfaces/main-categorie.interface';
moment.locale('es-mx');

@Injectable({
  providedIn: 'root',
})
export class TicketsServiceService {
  licencia: string =
    'ZTk4Yjg1MTlfXzIwMjUtMDEtMjVfXzIwMjUtMDctMjQjIyNURGZub1VqSExXOHRCbmZSL2R3N1E3Mk0vMTlBcXUzRDFGWkxha0NaTzQrMVpaQkZ4UFAybnpmbGJKeEd1RUZUNGVsTTB4SGJCV200Q3N5Z2ZnbkhFd0lWc2FRb2Zqdlp3d0VmRURsRExBT25ZTGE1YmhXd1JpRE9lMy9pZXg1MTByQTBXT0xneHdmcHpJTUZ5UTNVdVJUeTVQb3NUYktRdGhFTlRnM3ljSkZma29RS2hXUjgvQnYrRE9CM2NCVy81aUFJYVVzUEY4VWlxM050MzB6OWFjRDhFblRyQ0ZZZEpiQlhLdmVBbkRnS2FFdVFDN3BPdEJYRzBDZHJ1RktWdWpjOE01WStNYUU2MlY5Z1JVMkRHaDlLd1pFTDlaQndhSFRQVWF1VmVNeFlrQ25Gb1lhTXI4MUJvTWZ3WnJzNktBQzJieU1QMVRtWGRreVo3RVQreDk1ODB0aXl4VzRhQXFpMWtuNXJobFRuK1hPbzhsNWFmSW1Nby9mdlBCNkRabTU0UTdxc29NVVAyUnh3WXdRNHRpUisxam13eUpIeUVzeUNxcVVMRHB5SmQyTVd0Z2oxdVdmMWFkOEx6U3FsYTM0Umg2eEF4ZWp6bDZKY1pMVzFIVHJWemdiTFExWUpRZnhJOGQ5REVNWmEvYjNaYi9oREJ1Q2lKSCt4RTYzVQ==';
  conector: any;
  printerMAC: string = '66:22:C9:E9:79:6D';
  products: Product[] = [];
  movements: Movement[] = [];
  brands: Brand[] = [];
  categories: Categorie[] = [];
  url: string = 'http://localhost:8000';
  mainCategories: MainCategory[] = [];

  constructor(
    private _movementsService: MovementsService,
    private _productsService: ProductsService,
    private _brandsService: BrandsService,
    private _categoriesService: CategoriesService
  ) {}

  ticketHeader(): any {
    return this.conector
      .Iniciar()
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .DescargarImagenDeInternetEImprimir(
        'https://mrmavostore.com.mx/assets/mavomarcaaguanegro.png',
        0,
        576
      )
      .Iniciar() // En mi impresora debo invocar a "Iniciar" después de imprimir una imagen
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .Feed(1)
      .EscribirTexto('Calle Independencia #4, Zona Centro\n')
      .EscribirTexto('Cd. Valles, S.L.P., C.P. 79000\n')
      .Feed(1)
      .EscribirTexto('Horario de atencion:\n')
      .EscribirTexto('Lun-Sab de 8am a 8pm y Dom de 8am a 6pm\n')
      .Feed(1)
      .EscribirTexto('Whatsapp: 481-139-00-39\n')
      .EscribirTexto('Facebook: Mavo Store\n')
      .EscribirTexto('Sitio web: www.mrmavostore.com.mx\n')
      .Feed(1);
    // .EscribirTexto(
    //   'AGREGA nuestro numero a tu lista de contactos y se el primero en descubrir nuevos productos, promociones y descuentos exclusivos. \n\n ENVIANOS un WhatsApp para obtener un 10% de descuento en tu proxima compra, valido solo en productos sin descuento.\n\n No te pierdas esta oferta especial...'
    // )
    // .Feed(1);
  }

  ticketHeaderTwo(): any {
    return this.conector
      .Iniciar()
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .DescargarImagenDeInternetEImprimir(
        'https://mrmavostore.com.mx/assets/mavomarcaaguanegro.png',
        0,
        576
      )
      .Iniciar() // En mi impresora debo invocar a "Iniciar" después de imprimir una imagen
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .Feed(1)
      .EscribirTexto('Calle Independencia #4, Zona Centro\n')
      .EscribirTexto('Cd. Valles, S.L.P., C.P. 79000\n')
      .Feed(1);
  }

  async printSale(
    ticket: Ticket,
    categories: Categorie[],
    brands: Brand[],
    movements?: Movement[],
    fromTicketsSection?: boolean
  ): Promise<void> {
    this.categories = categories;
    this.brands = brands;
    this.conector = new ConectorEscposAndroid(this.licencia, this.url);
    await this.getTicketData(ticket, movements);
    this.ticketHeader()
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
      .EscribirTexto('Nota:' + `  #${ticket.globalTicketNumber}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
      .EscribirTexto('Fecha:' + ` ${this.getDateWithMoment(ticket.created)}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
      .EscribirTexto(
        'Estado de la nota:' +
          `  ${
            ticket.status === 1
              ? 'Completado'
              : ticket.status === 2
              ? 'Cambio'
              : 'Cancelado'
          }\n`
      )
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO);

    if (ticket.updated && ticket.updated > 0) {
      this.conector
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
        .EscribirTexto(
          'Actualizado:' + ` ${this.getDateWithMoment(ticket.updated)}\n`
        );
    }

    this.conector.EscribirTexto(this.getLine());

    for (const mov of this.movements) {
      this.conector
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
        .EscribirTexto(
          `-${this.removeAccentsAndSpecialChars(
            this._productsService.getProductName(mov.productUid, this.products)
          )}\n`
        )
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
        .EscribirTexto(
          `${mov.productsNumber} x ${this.formatCurrency(
            mov.costByUnit
          )} --> ${this.formatCurrency(mov.productsNumber * mov.costByUnit)}\n`
        );
      const product: Product = this._productsService.getProduct(
        mov.productUid,
        this.products
      );
      product.price = Number(product.price);
      if (mov.cartDiscount > 0 || product.offSale) {
        this.conector
          .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
          .EscribirTexto(`-Sin descuento -->\n`)
          .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
          .EscribirTexto(
            `${mov.productsNumber} x ${this.formatCurrency(
              this.getRealProductCostByUnit(product, mov)
            )} --> ${this.formatCurrency(
              mov.productsNumber * this.getRealProductCostByUnit(product, mov)
            )}\n`
          );
      }
      this.conector.Feed(1);
    }

    //PRINT OTHER SERVICES
    if (
      fromTicketsSection === true &&
      ticket.accumulatedSalesOfUninventoryedThings &&
      ticket.accumulatedSalesOfUninventoryedThings > 0
    ) {
      this.conector
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
        .EscribirTexto(`-Otros servicios\n`)
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
        .EscribirTexto(
          `${this.formatCurrency(
            ticket.accumulatedSalesOfUninventoryedThings
          )}\n`
        )
        .Feed(1);
    }

    this.conector
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .EscribirTexto(this.getLine())
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(`Subtotal: ${this.formatCurrency(ticket.subtotal)}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(`Total: ${this.formatCurrency(ticket.total)}\n`);

    if (ticket.subtotal > ticket.total) {
      this.conector
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
        .EscribirTexto(
          `Descuento: ${this.formatCurrency(ticket.subtotal - ticket.total)}\n`
        );
    }

    this.conector
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(
        `Pago ${ticket.paymentMethod === 1 ? 'en efectivo' : 'con tarjeta'}\n`
      );

    if (ticket.status === 1) {
      this.conector
        .Feed(1)
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
        .EscribirTexto(`Garantia de 7 dias solo en caso de DEFECTO...`)
        .Feed(1)
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
        .EscribirTexto(
          `IMPORTANTE: La garantia solo se aplica si presenta su TICKET de compra junto con el producto y su empaque original, incluyendo cualquier accesorio que venia con la compra del producto.`
        );
    }

    this.conector
      .Feed(1)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .EstablecerEnfatizado(true)
      .EstablecerTamañoFuente(1, 1)
      .EscribirTexto('Gracias por su compra.\n')
      .Feed(1)
      .Feed(2)
      .Feed(3)
      .Corte(1)
      .Pulso(48, 60, 120);

    try {
      const respuesta = await this.conector.imprimirEn(this.printerMAC);
      if (respuesta === true) {
        // alert('Impreso correctamente');
      } else {
        // alert('Error: ' + respuesta);
      }
    } catch (e) {
      // alert('Error imprimiendo: ' + e.message);
      throw 'Error imprimiendo: ' + e.message;
    }
  }

  getDateWithMoment(today: any): any {
    return moment(today).format('lll');
  }

  getLine(): string {
    return '---------------------------------------------\n';
  }

  async getTicketData(ticket: Ticket, movements?: Movement[]): Promise<void> {
    this.movements = [];
    if (movements && movements.length > 0) {
      this.movements = movements;
    } else {
      this.movements = await this._movementsService.getMovementsByTicketUid(
        ticket.uid
      );
    }
    this.products = [];
    for (const mov of this.movements) {
      const product: Product =
        await this._productsService.getProductByUidPromise(mov.productUid);
      this.products.push(product);
    }
    this.products = this.products.map((p) => {
      const product = {
        ...p,
        name: `${this._categoriesService.getCategorieName(
          p.categorieUid,
          this.categories
        )} ${this._brandsService.getBrandName(p.brandUid, this.brands)} ${
          p.name
        }`,
      };
      return product;
    });
  }

  private formatCurrency(value: number): string {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }

  getRealProductCostByUnit(product: Product, mov: Movement): any {
    if (product.offSale && product.previousPrice && product.previousPrice > 0) {
      return product.previousPrice;
    }
    if (!product.offSale && mov.cartDiscount > 0) {
      // return this.calculateOriginalValue(mov.costByUnit, mov.cartDiscount);
      return Number(product.price);
    }
    return 'No disponible';
  }

  calculateOriginalValue(newValue: number, cartDiscount: number): number {
    const valorOriginal = newValue / (1 - cartDiscount / 100);
    return Math.round(valorOriginal);
  }

  removeAccentsAndSpecialChars(inputString: string): string {
    const normalizedString = inputString.normalize('NFD');

    // const stringWithoutSpecialChars = normalizedString.replace(/[^\w\s]/g, '');
    // Conservar diagonales (/) y guiones (-) en la expresión regular
    const stringWithoutSpecialChars = normalizedString.replace(
      /[^\w\s\/-]/g,
      ''
    );

    return stringWithoutSpecialChars;
  }

  async printTotalSales(
    sales: any,
    tickets: Ticket[],
    categories: Categorie[],
    startDateParam: Date
  ): Promise<void> {
    this.conector = new ConectorEscposAndroid(this.licencia, this.url);
    const todayMovements: Movement[] = [];
    this.categories = categories;
    for (const t of tickets) {
      const movs = await this._movementsService.getMovementsByTicketUid(t.uid);
      todayMovements.push(...movs);
    }
    // console.log(todayMovements);

    const salesByCategory = todayMovements
      .filter((movement) => movement.categoryUid)
      .reduce((result, movement) => {
        const categoryUid = movement.categoryUid!;
        const totalSale =
          (result[categoryUid] || 0) +
          movement.productsNumber * movement.costByUnit;
        result[categoryUid] = totalSale;
        return result;
      }, {} as { [categoryUid: string]: number });

    const resultArray: any = Object.keys(salesByCategory).map(
      (categoryUid) => ({
        categoryUid,
        totalSale: salesByCategory[categoryUid],
      })
    );

    const newCategories: any[] = resultArray.map((ra) => {
      const mainCategoryUid: string = categories.find(
        (c) => c.uid === ra.categoryUid
      ).mainCategoryUid;
      return { ...ra, mainCategoryUid };
    });

    let finalArray: {
      mainCategoryUid: string;
      totalSale: number;
      name?: string;
    }[] = this.consolidateArrayByMainCategoryName(newCategories);

    const mainCategories: MainCategory[] =
      await this._categoriesService.getMainCategories();

    finalArray = finalArray.map((c) => {
      const categoryName: string = mainCategories.find(
        (mc) => c.mainCategoryUid === mc.uid
      ).name;

      return { ...c, name: categoryName };
    });

    finalArray = finalArray.sort((a, b) => a.name.localeCompare(b.name));

    // console.log(finalArray);

    this.ticketHeaderTwo()
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_IZQUIERDA)
      .EscribirTexto('Fecha:' + ` ${this.getDateWithMoment(startDateParam)}\n`);

    for (const r of finalArray) {
      this.conector
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
        .EscribirTexto(this.getLine())
        .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
        .EscribirTexto(
          `${this.removeAccentsAndSpecialChars(
            r && r.name ? r.name : ''
          )}:\n ${this.formatCurrency(r.totalSale)}\n`
        );
    }

    this.conector
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .EscribirTexto(this.getLine())
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(`Total: ${this.formatCurrency(sales.all)}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(`Efectivo: ${this.formatCurrency(sales.cash)}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_DERECHA)
      .EscribirTexto(`Tarjeta: ${this.formatCurrency(sales.card)}\n`)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .EscribirTexto(this.getLine());

    this.conector
      .Feed(1)
      .EstablecerAlineacion(ConectorEscposAndroid.ALINEACION_CENTRO)
      .EstablecerEnfatizado(true)
      .EstablecerTamañoFuente(1, 1)
      .EscribirTexto('RESUMEN DE VENTA.\n')
      .Feed(1)
      .Feed(2)
      .Feed(3)
      .Corte(1)
      .Pulso(48, 60, 120);

    try {
      const respuesta = await this.conector.imprimirEn(this.printerMAC);
      if (respuesta === true) {
        // alert('Impreso correctamente');
      } else {
        // alert('Error: ' + respuesta);
      }
    } catch (e) {
      // alert('Error imprimiendo: ' + e.message);
      throw 'Error imprimiendo: ' + e.message;
    }
  }

  consolidateArrayByMainCategoryName(arrayOriginal: any[]): any[] {
    const consolidatedArray = arrayOriginal.reduce((accumulator, current) => {
      const existingItem = accumulator.find(
        (item) => item.mainCategoryUid === current.mainCategoryUid
      );

      if (existingItem) {
        // Si ya existe un objeto con el mismo mainCategoryName, sumar los valores
        existingItem.totalSale += current.totalSale;
      } else {
        // Si no existe, agregar un nuevo objeto al acumulador
        accumulator.push({
          mainCategoryUid: current.mainCategoryUid,
          totalSale: current.totalSale,
        });
      }

      return accumulator;
    }, []);

    return consolidatedArray;
  }
}
