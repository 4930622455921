import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Product } from '../../../interfaces/product.interface';
import { Movement } from 'src/app/interfaces/movement';
import { MovementsService } from 'src/app/providers/movements.service';
import { AlertsService } from 'src/app/providers/alerts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesService } from 'src/app/providers/categories.service';
import { BrandsService } from 'src/app/providers/brands.service';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Brand } from 'src/app/interfaces/brand.interface';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Ticket } from 'src/app/interfaces/ticket.interface';
import { TicketsService } from 'src/app/providers/tickets.service';

@Component({
  selector: 'app-save-movement',
  templateUrl: './save-movement.component.html',
  styleUrls: ['./save-movement.component.scss'],
})
export class SaveMovementComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() categories: Categorie[] = [];
  @Input() brands: Brand[] = [];
  @Input() productsCopy: Product[] = [];
  @Input() movementSelected: Movement = null;
  @Input() edit: boolean;
  @Output() resetEditMovementData: EventEmitter<boolean> = new EventEmitter();
  @Output() clean: EventEmitter<boolean> = new EventEmitter();
  movementForm: FormGroup;
  categorySelected: string = '';
  productSelected: string = '';
  loading: boolean;
  productStock: number = 0;
  productsString: string[] = [];
  productNameSelected: string;
  editCreatedDate: boolean;
  createdDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  createdDateTime: any = {
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
  };
  addTicketUidActive: boolean;
  tickeNumber: number;
  ticketUid: string;

  constructor(
    private _movementsService: MovementsService,
    private _alertsService: AlertsService,
    private _modalService: NgbModal,
    private _categoriesService: CategoriesService,
    private _brandsService: BrandsService,
    private _ticketsService: TicketsService
  ) {
    this.movementForm = new FormGroup({
      type: new FormControl('3', [Validators.required]),
      costByUnit: new FormControl('', [Validators.required]),
      productsNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      ]),
    });
  }

  ngOnInit(): void {
    this.productsString = this.productsCopy.map((p) => p.name);
    if (this.edit === true) {
      this.productSelected = this.movementSelected.productUid;
      this.productNameSelected = this.productsCopy.find(
        (p) => p.uid === this.productSelected
      ).name;
      this.movementForm.setValue({
        type: this.movementSelected.type,
        costByUnit: this.movementSelected.costByUnit,
        productsNumber: this.movementSelected.productsNumber,
      });
      this.checkStock();
    }
  }

  changeProductSelected(event: string) {
    const product: Product = this.productsCopy.find((p) => p.name === event);
    this.productSelected = product.uid;
    const costByUnit =
      product && product.price && product.price > 0 ? product.price : 0;
    this.movementForm.controls['costByUnit'].setValue(costByUnit);
    this.movementForm.controls['type'].setValue('3');

    this.checkStock();
  }

  async alertConfirmationWithData(data: Movement, title: string): Promise<any> {
    const { costByUnit, productsNumber, type } = data;
    const productName = this.products.find(
      (p) => p.uid === this.productSelected
    ).name;
    const alertResponse: any = await this._alertsService.movementConfirmation(
      title,
      productName,
      productsNumber,
      costByUnit,
      type === 1 ? 'Entrada' : type === 2 ? 'Salida' : 'Venta'
    );

    return alertResponse;
  }

  async saveMovement() {
    try {
      const alertResponse: any = await this.alertConfirmationWithData(
        this.movementForm.value,
        '¿Deseas continuar?'
      );
      if (alertResponse.value) {
        const categoryUid: string = this.productsCopy.find(
          (p) => p.uid === this.productSelected
        ).categorieUid;
        let movement: Movement = {
          ...this.movementForm.value,
          costByUnit: Number(this.movementForm.value.costByUnit),
          productsNumber: Number(this.movementForm.value.productsNumber),
          type: Number(this.movementForm.value.type),
          productUid: this.productSelected,
          categoryUid: categoryUid,
          created: this.editCreatedDate
            ? this.newCreatedDate()
            : new Date().getTime(),
        };
        this.loading = true;
        if (this.edit === true) {
          !this.editCreatedDate && delete movement.created;
          movement.updated = new Date().getTime();
          await this._movementsService.editMovement({
            ...movement,
            uid:
              this.movementSelected && this.movementSelected.uid === undefined
                ? this.movementSelected.id
                : this.movementSelected.uid,
          });
          this.resetEditMovementData.emit(true);
          this._modalService.dismissAll();
        } else {
          let movementToSave = movement;
          if (this.addTicketUidActive && this.tickeNumber > 0) {
            const ticket: Ticket =
              await this.searchTicketByglobalTicketNumber();

            if (ticket !== null && ticket.uid) {
              movementToSave = {
                ...movement,
                ticketUid: ticket.uid,
                created: ticket.created,
              };
            } else {
              this._alertsService.toastAlert(
                'error',
                'No se encontro el ticket'
              );
            }
          }
          await this._movementsService.addMovement(movementToSave);
        }
        this._alertsService.toastAlert(
          'success',
          `Producto ${this.edit === true ? 'actualizado' : 'guardado'}...`
        );
        this.movementForm.reset();
        this.addTicketUidActive = false;
        if (this.editCreatedDate) {
          this.updateTimePicker();
        }
        await this.checkStock();
        // this.clean.emit(true);
      }
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }

  newCreatedDate(): number {
    return this.convertToTimestamp();
  }

  getItemsNumber(movementsArray: Movement[]): number {
    const items: number[] = [];

    movementsArray.map((m) => items.push(Number(m.productsNumber)));

    const value: number = items.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);

    return value;
  }

  validateIfCanSave(): boolean {
    if (
      this.productStock === 0 &&
      Number(this.movementForm.value.type) !== 1 &&
      !this.edit
    ) {
      return false;
    }
    if (
      this.productStock < Number(this.movementForm.value.productsNumber) &&
      Number(this.movementForm.value.type) !== 1 &&
      !this.edit
    ) {
      return false;
    }
    if (
      !this.edit &&
      this.addTicketUidActive &&
      (this.tickeNumber === 0 || !this.tickeNumber)
    ) {
      return false;
    }
    return true;
  }

  async checkStock(): Promise<void> {
    const movs = await this._movementsService.getMovementsByProductUidOnce(
      this.productSelected
    );

    const productMovements: Movement[] = movs.filter(
      (m) => m.productUid === this.productSelected
    );
    const productMovementsEntries = productMovements.filter(
      (m) => Number(m.type) === 1
    );
    if (productMovementsEntries.length > 0) {
      const entries: number = this.getItemsNumber(productMovementsEntries);
      const removals: number = this.getItemsNumber(
        productMovements.filter((m) => Number(m.type) === 2)
      );
      const sales: number = this.getItemsNumber(
        productMovements.filter((m) => Number(m.type) === 3)
      );

      this.productStock = entries - removals - sales;

      if (this.productStock === 0) {
        this.movementForm.controls['productsNumber'].setValue(0);
      } else {
        this.movementForm.controls['productsNumber'].setValue(1);
      }
    }
  }

  changeCreatedDateValue(event: any): void {
    const value = event.target.checked;
    this.editCreatedDate = value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.movements && changes.movements.currentValue) {
      this.checkStock();
    }
  }

  updateTimePicker(): void {
    this.editCreatedDate = false;
    // this.createdDateTime.hour = new Date().getHours();
    this.createdDateTime.minute = new Date().getMinutes();
    setTimeout(() => {
      this.editCreatedDate = true;
    }, 100);
  }

  convertToTimestamp(): number {
    const { year, month, day } = this.createdDate;
    const hours = this.createdDateTime.hour;
    const minutes = this.createdDateTime.minute;
    const seconds = new Date().getSeconds();
    const milliseconds = 0;

    const jsMonth = month - 1;
    const timestamp = new Date(
      year,
      jsMonth,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    ).getTime();

    return timestamp;
  }

  trySave(): void {
    if (
      this.movementForm.valid &&
      this.productSelected !== '' &&
      this.validateIfCanSave()
    ) {
      this.saveMovement();
    }
  }

  async searchTicketByglobalTicketNumber(): Promise<Ticket> {
    if (this.tickeNumber && this.tickeNumber > 0) {
      const tickets: any = await this._ticketsService.getTicketByField(
        'globalTicketNumber',
        this.tickeNumber,
        '=='
      );
      return tickets[0];
    }
    return null;
  }
}
