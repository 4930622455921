import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Inventory } from '../interfaces/inventory.interface';

@Injectable({
  providedIn: 'root',
})
export class InventoriesService {
  private inventoriesCollection: AngularFirestoreCollection<Inventory>;

  constructor(private _afs: AngularFirestore) {
    this.inventoriesCollection = _afs.collection<Inventory>('inventories');
  }

  async addInventory(inventory: any): Promise<void> {
    const id = this._afs.createId();

    const inventoryToSave: Inventory = {
      uid: id,
      ...inventory,
    };
    this.inventoriesCollection.doc(id).set(inventoryToSave);
  }
}
