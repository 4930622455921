import { Component, OnInit } from '@angular/core';
import { Inventory } from 'src/app/interfaces/inventory.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { InventoriesService } from 'src/app/providers/inventories.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  constructor(
    private _inventoriesService: InventoriesService,
    private _alertsService: AlertsService
  ) {}

  ngOnInit(): void {}

  async createInventory(): Promise<void> {
    try {
      const alertConfirmation = await this._alertsService.confirmation(
        'Vas a crear un nuevo inventario'
      );
      console.log(alertConfirmation, 'HOLA');
      if (alertConfirmation.isConfirmed) {
        this._alertsService.loading();
        const newInventory: Inventory = {
          status: 1,
          created: new Date().getTime(),
        };
        await this._inventoriesService.addInventory(newInventory);
        this._alertsService.toastAlert('success', 'Nuevo inventario creado...');
      }
    } catch (error) {
      console.log(error, 'ERROR');

      this._alertsService.toastAlert('error', 'Hubo un error...');
    }
  }
}
