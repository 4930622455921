import { Component, Input, OnInit } from '@angular/core';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { Movement } from 'src/app/interfaces/movement';
import { Product } from 'src/app/interfaces/product.interface';
import { Stock } from 'src/app/interfaces/stock.interface';
import { MovementsService } from 'src/app/providers/movements.service';
import * as moment from 'moment';
import { ProductsService } from 'src/app/providers/products.service';
import { AlertsService } from 'src/app/providers/alerts.service';
moment.locale('es-mx');

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})
export class StockComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() movements: Movement[] = [];
  @Input() categories: Categorie[] = [];
  stockData: Stock[] = [];
  stockDataCopy: Stock[] = [];
  categorySelected: string | null;
  outOfStock: false;
  valuesForFIlterOutOfStock: number[] = [-1, 0, 3, 5, 10, 15, 20];
  fIlterOutOfStock: number;

  constructor(
    private _movementsService: MovementsService,
    private _alertsService: AlertsService,
    public _productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.stockData = this.products.map((p: Product) => {
      return {
        productUid: p.uid,
        stock: 0,
        lastMovementDate: null,
        categoryUid: p.categorieUid,
        outOfStock: p.outOfStock,
        photoUrl: p.photoUrl,
      };
    });
    this.stockData.forEach((item, i) => {
      const productMovements: Movement[] = this.movements.filter(
        (m) => item.productUid === m.productUid
      );
      const entries: number = this._movementsService.getItemsNumber(
        this.getMovementsByType(productMovements, 1)
      );
      const removals: number = this._movementsService.getItemsNumber(
        this.getMovementsByType(productMovements, 2)
      );
      const sales: number = this._movementsService.getItemsNumber(
        this.getMovementsByType(productMovements, 3)
      );

      const stock: number = entries - removals - sales;
      this.stockData[i].stock = stock;
      this.stockData[i].lastMovementDate =
        this.getLastMovementDate(productMovements);
    });
    this.stockDataCopy = this.stockData;
  }

  getMovementsByType(movements: Movement[], type: number): Movement[] {
    return movements.filter((m) => m.type === type);
  }

  getLastMovementDate(movements: Movement[]): number {
    if (movements.length === 0) {
      return null;
    }

    const lastMovement = movements.reduce((prev, current) =>
      prev.created > current.created ? prev : current
    );

    return lastMovement.created;
  }

  selectCategory(event: any): void {
    this.outOfStock = false;
    // this.fIlterOutOfStock = 0;
    this.categorySelected = event.target.value;
    this.stockData = this.stockDataCopy.filter(
      (sc) => sc.categoryUid === this.categorySelected && sc.stock === 0
    );
    this.filterByOutOfStock();
  }

  getDate(date: number | Date): any {
    return moment(new Date(date)).format('ll');
  }

  sortByDate(data: any[]): any[] {
    return data.sort((a, b) => b.lastMovementDate - a.lastMovementDate);
  }

  filterByOutOfStock(): void {
    const stock: number = this.fIlterOutOfStock;
    // console.log(this.stockDataCopy, 'STOCK DATA COPY');

    if (!this.outOfStock) {
      this.stockData = this.stockDataCopy.filter(
        (p) =>
          !p.outOfStock &&
          p.categoryUid === this.categorySelected &&
          (stock < 0 ? true : p.stock <= stock)
      );
    } else {
      this.stockData = this.stockDataCopy.filter(
        (p) =>
          p.outOfStock &&
          p.categoryUid === this.categorySelected &&
          p.stock <= stock
      );
    }
    this.stockData = this.sortByDate(this.stockData);
  }

  selectStock(): void {
    this.filterByOutOfStock();
  }

  customSort(field: string): void {
    this.stockData = this.stockData.sort((a, b) => b[field] - a[field]);
  }

  openImage(image: string) {
    if (image && image.length > 0) {
      this._alertsService.openImageFromCheckout(image, '');
    }
  }
}
