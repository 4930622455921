<div class="container pb-4 p-lg-5">
  <div class="col-md-12">
    <app-product-finder
      [options]="productsString"
      (sendData)="changeProductSelected($event)"
      [productNameSelected]="productNameSelected"
    ></app-product-finder>
  </div>
  <form [formGroup]="movementForm">
    <div class="row">
      <div class="col-md-12 mt-3">
        <select
          class="form-control"
          type="text"
          id="typeSelect"
          formControlName="type"
        >
          <option value="">Tipo</option>
          <option value="1">Entrada</option>
          <option value="2">Salida</option>
          <option value="3">Venta</option>
        </select>
      </div>
      <div class="col-md-12 mt-3">
        <input
          class="form-control"
          type="number"
          id="productsNumber"
          placeholder="Cantidad..."
          formControlName="productsNumber"
          autocomplete="off"
        />
      </div>
      <div class="col-md-12 mt-3">
        <input
          class="form-control"
          type="number"
          id="costByUnit"
          placeholder="Precio..."
          formControlName="costByUnit"
          autocomplete="off"
          (keyup.enter)="trySave()"
        />
      </div>
    </div>
  </form>
  <div class="col-md-12 mt-3">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        id="editCreatedDate"
        style="cursor: pointer"
        (change)="changeCreatedDateValue($event)"
      />
      <label class="form-check-label" for="editCreatedDate">Editar fecha</label>
    </div>
  </div>
  <div
    class="row"
    *ngIf="!edit && movementForm.value.type && movementForm.value.type === '3'"
  >
    <div class="col-md-12">
      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="addTicketUidActive"
          id="flexCheckTicketUid"
        />
        <label class="form-check-label" for="flexCheckTicketUid">
          Agregar ticket
        </label>
      </div>
    </div>
    <div class="col-md-12 mt-2" *ngIf="addTicketUidActive">
      <input
        class="form-control"
        type="number"
        id="tickeNumber"
        placeholder="N° de ticket..."
        [(ngModel)]="tickeNumber"
        autocomplete="off"
      />
    </div>
  </div>
  <div class="col-md-12" *ngIf="editCreatedDate">
    <div class="row">
      <div class="col-md-6 mt-3 date">
        <div class="input-group w-100">
          <input
            class="form-control"
            placeholder="Fecha"
            name="dp"
            [(ngModel)]="createdDate"
            ngbDatepicker
            #d="ngbDatepicker"
            autocomplete="off"
          />
          <button
            class="btn btn-outline-secondary"
            (click)="d.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6 mt-3 date">
        <ngb-timepicker [(ngModel)]="createdDateTime"></ngb-timepicker>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-3">
    <span
      class="badge bg-success text-white"
      [ngClass]="{ 'bg-danger': productStock === 0 }"
      >{{ productStock }}</span
    >
  </div>
  <div class="col-md-12 mt-3 mb-2">
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-primary"
        [disabled]="
          !movementForm.valid || productSelected === '' || !validateIfCanSave()
        "
        (click)="saveMovement()"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
