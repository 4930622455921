<div class="container">
  <div class="row">
    <div class="col-4">
      <div class="d-flex justify-content-start pt-2 pb-2">
        <app-back-button [mini]="true"></app-back-button>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-center pt-2 pb-2">
        <button class="btn btn-primary btn-sm" (click)="createInventory()">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
