import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelReportsService {
  constructor() {}

  generateDefaultReport(
    data: any[],
    filename: string,
    customHeaders?: any[]
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        let worksheetData: any[] = [];

        Object(data).forEach((item: any) => {
          let worksheetItem = Object();
          customHeaders.forEach((header) => {
            worksheetItem[header.name] = item[header.key];
          });
          worksheetData.push(worksheetItem);
        });

        // excel file
        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja 1');
        XLSX.writeFileXLSX(workbook, filename, {});

        resolve(`Reporte generado exitosamente: ${filename}`);
      } catch (error) {
        reject(`Error al generar el reporte: ${error.message}`);
      }
    });
  }
}
