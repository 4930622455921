import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AdminRoutingModule } from './admin-routing.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbModule,
  NgbNavModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

import { SortByLetterPipe } from '../pipes/sort-by-letter.pipe';

import { AdminComponent } from './admin.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { AdminPaginationComponent } from './shared/admin-pagination/admin-pagination.component';
import { BackButtonComponent } from './shared/back-button/back-button.component';
import { BrandsComponent } from './brands/brands.component';
import { BrandSelectComponent } from './shared/brand-select/brand-select.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategorySelectComponent } from './shared/category-select/category-select.component';
import { MovementsComponent } from './movements/movements.component';
import { NameInputComponent } from './shared/name-input/name-input.component';
import { ProductGalleryComponent } from './product-gallery/product-gallery.component';
import { ProductsComponent } from './products/products.component';
import { ProductSelectComponent } from './shared/product-select/product-select.component';
import { SaveMovementComponent } from './shared/save-movement/save-movement.component';
import { StockSummaryComponent } from './stock-summary/stock-summary.component';
import { CouponsComponent } from './coupons/coupons.component';
import { SharedModule } from '../modules/shared/shared.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SalesComponent } from './sales/sales.component';
import { StockComponent } from './shared/stock/stock.component';
import { SoldProductsReportComponent } from './shared/sold-products-report/sold-products-report.component';
import { InventoryComponent } from './inventory/inventory.component';

@NgModule({
  declarations: [
    AdminComponent,
    MovementsComponent,
    ProductsComponent,
    StockSummaryComponent,
    CategoriesComponent,
    BrandsComponent,
    NameInputComponent,
    SortByLetterPipe,
    CategorySelectComponent,
    BrandSelectComponent,
    ProductSelectComponent,
    AdminPaginationComponent,
    ProductGalleryComponent,
    AdminMenuComponent,
    BackButtonComponent,
    SaveMovementComponent,
    CouponsComponent,
    SalesComponent,
    StockComponent,
    SoldProductsReportComponent,
    InventoryComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbTimepickerModule,
    AngularEditorModule,
    HttpClientModule,
    SharedModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [SortByLetterPipe],
})
export class AdminModule {}
